import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cibEyeem, cilLockLocked, cilUser } from '@coreui/icons'
import axios from 'axios'
import { LOGIN_URL, USER_LOGIN_URL } from 'src/constant'
import Swal from 'sweetalert';

const UserLogin = () => {



  const navigate = useNavigate()


  const token = localStorage.getItem("user_token")

  console.log("tokkk", token)


  useEffect(() => {

    if (token) {
      navigate("/voucherlist")

    }

  }, [])









  const [user_name, setUser_name] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState({})


  const LoginFun = async () => {

    console.log("user_name", user_name)
    console.log("password", password)


    const body = {
      "user_name": user_name,
      "password": password
    }

    // navigate("/dashboard")
    try {
      const Response = await axios({
        method: "post",
        url: `${USER_LOGIN_URL}`,
        data: body
      })

      console.log("Response-->", Response.data)
      // if (Response.data.succes == true) {

        localStorage.setItem("user_token", Response.data.token)
        localStorage.setItem("user_type", Response.data.type)

        Swal({
          icon: 'success',
          title: 'Success!',
          text: Response.data.message,
          timer: 1500, // Set the timer to 2000 milliseconds (2 seconds)
          showConfirmButton: false, // Hide the "OK" button
        })

        setTimeout(() => {

          navigate("/voucherlist")
        }, 1500);

      // }

    }
    catch (err) {

      console.log("err-->", err.response.data.errors)
      setError(err.response.data.errors)
    }



  };



  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <CCardGroup>
              <CCard className="p-4" >
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText >
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput onChange={(e) => setUser_name(e.target.value)} placeholder="Username" autoComplete="username" />
                    </CInputGroup>
                    {error.user_name ?
                      <p style={{ color: "red", fontSize: 14 }}>{error.user_name}</p>
                      : null}
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    {error.password ?
                      <p style={{ color: "red", fontSize: 14 }}>{error.password}</p>
                      : null}
                    <CRow>
                      <CCol xs={6}>
                        <CButton onClick={() => LoginFun()} color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>

            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default UserLogin
