import React, { Component, Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './scss/style.scss'
import UserLogin from './Screens/UserLogin'
import Adminlogin from './Screens/Adminlogin'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Login1 = React.lazy(() => import('./Screens/UserLogin'))
const Login2 = React.lazy(() => import('./Screens/Adminlogin'))

const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))



// class App extends Component {




//   render() {
//     return (
//       <BrowserRouter>
//         <Suspense fallback={loading}>
//           <Routes>
//             <Route path="/login" name="Login Page" element={<Login />} />
//             <Route exact path="/register" name="Register Page" element={<Register />} />
//             <Route exact path="/404" name="Page 404" element={<Page404 />} />
//             <Route exact path="/500" name="Page 500" element={<Page500 />} />
//             <Route path="*" name="Home" element={<DefaultLayout />} />

//             <Route path="/" element={<Navigate to="login" replace />} />

//           </Routes>
//         </Suspense>
//       </BrowserRouter>
//     )
//   }
// }

// export default App




const App = () => {



  const token = localStorage.getItem("token")
  console.log("tokkk", token)

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route exact path="/admin/login" element={<Login2 />} />
          <Route exact path="/user/login" element={<Login1 />} /> */}
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/400" element={<Page404 />} />
          <Route exact path="/adminlogin" element={<Page500 />} />
          <Route exact path="/userlogin" element={<Login />} />

          <Route path="*" element={<DefaultLayout />} />

          {/* <Route path="/" element={<Navigate to={token ? "stafflist" : "login"} replace />} /> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
